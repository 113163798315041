import React from 'react';
import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="page-404" style={{ padding: '32px 0' }}>
          <picture>
            <source
              srcSet="/images/illustrations/plant2.webp"
              type="image/webp"
            />
            <source
              srcSet="/images/illustrations/plant2.png"
              type="image/png"
            />
            <img
              src="/images/illustrations/plant2.png"
              alt="plant"
              style={{ height: '128px', marginBottom: '16px' }}
            />
          </picture>
          <iframe
            style={{ width: '99vw', height: '100vh' }}
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ1Sinn6j84olWHD2yC7TR6Rb_xfNufDhx3_3Gejz5g_oU0nQP_dlZhMQdzFqui4kJ_2TTlvRrE7H7z/pubhtml?gid=1312539311&amp;single=true&amp;widget=true&amp;headers=false"
          ></iframe>
          <picture>
            <source srcSet="/images/illustrations/dog.webp" type="image/webp" />
            <source srcSet="/images/illustrations/dog.png" type="image/png" />
            <img
              src="/images/illustrations/dog.png"
              alt="dog"
              style={{ height: '196px', margin: '32px 0 0 0' }}
            />
          </picture>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
